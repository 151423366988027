;(function() {
	'use strict';

	var Boxzilla = window.Boxzilla,
		queue = [];

	function pushQueue() {
		if( queue.length <= 0 ) {
			return;
		}

		var data = JSON.stringify(queue);

		if (typeof(navigator.sendBeacon) === "function") {
			navigator.sendBeacon(boxzilla_stats_config.ajaxurl, data);
		} else {
			var request = new XMLHttpRequest();
			request.open('POST', boxzilla_stats_config.ajaxurl, false);
			request.setRequestHeader('Content-Type', 'application/json');
			request.send(data);
		}

		queue = [];
	}

	Boxzilla.on('box.show', function( box ) {
		queue.push([box.id, 'views']);
	});

	Boxzilla.on('box.dismiss', function( box ) {
        queue.push([box.id, 'dismissed']);
	});

	Boxzilla.on('box.interactions.link', function( box, link ) {
        queue.push([box.id, 'interactions']);
	});

	Boxzilla.on('box.interactions.form', function( box, form ) {
        queue.push([box.id, 'interactions']);
	});

	window.addEventListener('beforeunload', pushQueue);
	window.addEventListener('unload', pushQueue);
})();

